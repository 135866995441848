import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

import * as ga from "../lib/ga";
import "../styles/customTheme.scss";
//import '../styles/globals.css'
//import 'bootstrap/dist/css/bootstrap.min.css';
import initAuth from "../utils/initAuth";
import Head from "next/head";
//import Script from "next/script";
import CookieConsent, {
  //Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";

//TODO
//DONE sitemap
//DONE onboarding
//DONE link to google analytics
//DONE Lighthouse performance
//DONE analytics search log
//DONE cookie policy
//DONE map view of search results
//DONE cache geocoder
//DONE job title slugs
//DONE email when new org added
//DONE api unit tests

//How-to guide
//map view needs infinite scroll for search results

//get rid of full page loader
//get rid of TODOs
//what if geocoder fails?

//editing after posting is live
//stop duplicate orgs from being added

//typescript
//testing - unit, golden, integration

//SEO
//page speed

//404 page

//organisations pages (add to sitemap)
//organisation logo
//ability to embed posting on own website

//admin dashboard
//customised search results
//(privacy policy)

//adverts
//advert performance
//job listings performance

//Advertisers?
//PrayerMate
//Christianity Explored
//10ofThose
//Cornhill
//P7
//Go Chatter

initAuth();

function MyApp({ Component, pageProps }: { Component: any; pageProps: any }) {
  const router = useRouter();
  const [cookieConsentGiven, setCookieConsentGiven] = useState(
    getCookieConsentValue()
  );

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <>
      <Head>
        <title>Vacancies.church</title>
        <meta
          key="description"
          name="description"
          content="Advertise your church jobs with us and to get the person with the right skills and able to handle the responsibilities"
        />
        <meta
          key="ogdescription"
          property="og:description"
          content="Advertise your church jobs with us and to get the person with the right skills and able to handle the responsibilities"
        />
        <meta key="ogtitle" property="og:title" content="Vacancies.church" />
        <meta key="ogtype" property="og:type" content="website" />
        <meta
          key="ogimage"
          property="og:image"
          content="https://s3.eu-west-1.amazonaws.com/vacancies.church/og_image.png"
        />
        <meta
          key="ogurl"
          property="og:url"
          content={"https://vacancies.church" + router.asPath}
        />
        <meta property="fb:app_id" content="1506156789779264" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@vacancieschurch" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
        {cookieConsentGiven ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {'analytics_storage': 'denied'});
            gtag('consent', 'update', {'analytics_storage': 'granted'});
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
        ) : (
          <script
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {'analytics_storage': 'denied'});
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
            page_path: window.location.pathname,
          });
        `,
            }}
          />
        )}
      </Head>

      <CookieConsent
        location="bottom"
        buttonText="Accept"
        //cookieName="myAwesomeCookieName2"
        enableDeclineButton
        declineButtonText="Decline"
        //style={{ background: "#2B373B" }}
        //buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        //containerClasses="alert alert-warning col-6"
        expires={365}
        onAccept={() => setCookieConsentGiven("true")}
        onDecline={() => setCookieConsentGiven("false")}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
